import React, { useState, useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  FormGroup,
  Label,
  Input,
  Badge,
  Button,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Loader from 'react-loader-spinner';
import 'react-image-lightbox/style.css';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';
import moment from 'moment';

const MobileApp = () => {
  const [breadcrumbItems] = useState([
    { title: 'KD Mobile App', link: '#' },
    { title: 'Details', link: '#' },
  ]);
  const [appInfoLoading, setAppInfoLoading] = useState(true);
  const [appInfo, setAppInfo] = useState({});
  const [shop_app_updating, set_shop_app_updating] = useState(false);
  const [rider_app_updating, set_rider_app_updating] = useState(false);
  const [notification_sending, set_notification_sending] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [isSucceed, setIsSucceed] = useState(false);
  const [whatsappDetail, setWhatsappDetail] = useState({
    accessToken: "",
    instanceId: ""
  })
  const [apiUpdating, setApiUpdating] = useState(false)

  const shop_notification_form = useRef();
  const rider_notification_form = useRef();

  useEffect(() => {
    setAuthToken();
    sendRequest
      .get('/mobile_app')
      .then(({ data }) => {
        setAppInfo(data.app_info);
        setAppInfoLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    sendRequest.get('/mobile_app/whatsapp-data').then(({ data }) => {
      // setWhatsappDetail()
      console.log(data)
      setWhatsappDetail({
        accessToken: data.access_token,
        instanceId: data.instance_id
      })
    }).catch((err) => {
      console.log(err)
    })

    // eslint-disable-next-line
  }, []);

  const handleApiUpdate = async (event, values) => {
    console.log(values)
    setApiUpdating(true);
    try {
      try {
        setAuthToken();
        const { data } = await sendRequest.post('/mobile_app/whatsapp-data', {
          instance_id: values.instance_id,
          access_token: values.access_token
        });
        setOpenResponseModal(true);
        setIsSucceed(true);
        setResponseMessage("Whatsapp Data set successfully");
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
    setApiUpdating(false);
  }

  const handleShopAppUpdate = async (event, values) => {
    set_shop_app_updating(true);
    try {
      try {
        setAuthToken();
        const { data } = await sendRequest.put('/mobile_app/shop_app', {
          version: values.shop_app_version,
          build_number: values.shop_app_build_number,
        });
        setAppInfo({ ...appInfo, shop_app: data.update_info });
      } catch (e) {
        console.log(e);
      }
    } catch (e) {
      console.log(e);
    }
    set_shop_app_updating(false);
  };

  const handleRiderAppUpdate = async (event, values) => {
    set_rider_app_updating(true);
    try {
      setAuthToken();
      const { data } = await sendRequest.put('/mobile_app/rider_app', {
        version: values.rider_app_version,
        build_number: values.rider_app_build_number,
      });
      setAppInfo({ ...appInfo, rider_app: data.update_info });
    } catch (e) {
      console.log(e);
    }
    set_rider_app_updating(false);
  };

  const handleSendNotification = (app_type) => async (event, values) => {
    set_notification_sending(true);
    try {
      setAuthToken();
      const result = await sendRequest.post(`/mobile_app/${app_type}/notification`, {
        notification_body: values.notification_body,
        app_type,
      });

      setOpenResponseModal(true);
      setIsSucceed(true);
      setResponseMessage(result.data.message);

      if (app_type === 'shop_app') {
        shop_notification_form && shop_notification_form.current.reset();
      }
      if (app_type === 'rider_app') {
        rider_notification_form && rider_notification_form.current.reset();
      }
    } catch (e) {
      console.log(e);
    }
    set_notification_sending(false);
  };




  return (
    <React.Fragment>
      {openResponseModal && (
        <SweetAlert
          success={isSucceed}
          error={!isSucceed}
          title={isSucceed ? 'Success' : 'Error'}
          onConfirm={() => {
            setOpenResponseModal(false);
          }}
        >
          {responseMessage}
        </SweetAlert>
      )}
      <div className='page-content'>
        <Container fluid>
          <Breadcrumbs title='View App Details' breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12}>
              <Card>
                {appInfoLoading ? (
                  <div className='text-center my-3'>
                    <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                  </div>
                ) : !appInfoLoading && !appInfo ? (
                  <CardBody>
                    <h4 className='text-center'>No Data Found</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <h5 className='text-capitalize mb-3'>
                          KD Shop App
                          <Badge pill className='badge-soft-success font-size-14 ml-2'>
                            Active
                          </Badge>
                        </h5>
                        <AvForm className='needs-validation' onValidSubmit={handleShopAppUpdate}>
                          <Row>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Version</Label>
                                <AvField
                                  name='shop_app_version'
                                  placeholder='Enter Version'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Version is required.',
                                    },
                                  }}
                                  value={appInfo.shop_app.version}
                                  disabled={shop_app_updating}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Build Number</Label>
                                <AvField
                                  name='shop_app_build_number'
                                  placeholder='Enter Build Number'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Build Number is required.',
                                    },
                                  }}
                                  value={appInfo.shop_app.build_number}
                                  disabled={shop_app_updating}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Last Updated</Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  readOnly
                                  value={moment(new Date(appInfo.shop_app.last_updated)).format(
                                    'DD-MM-YYYY'
                                  )}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label style={{ opacity: 0 }}>update</Label>
                                <br />
                                <Button
                                  color='info'
                                  className='waves-effect waves-light mr-3'
                                  type='submit'
                                  disabled={shop_app_updating}
                                >
                                  {shop_app_updating ? 'Updating Info ...' : 'Update App'}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </AvForm>
                        <hr className='my-3' />
                        <Row>
                          <Col md='12'>
                            <AvForm
                              className='needs-validation'
                              onValidSubmit={handleSendNotification('shop_app')}
                              ref={shop_notification_form}
                            >
                              <FormGroup>
                                <Label>KD App Update Details</Label>
                                <AvField
                                  name='notification_body'
                                  placeholder='Enter Notification Details ...'
                                  type='textarea'
                                  className='form-control'
                                  disabled={notification_sending}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Description is required',
                                    },
                                  }}
                                />
                              </FormGroup>
                              <Button
                                color='success'
                                className='waves-effect waves-light mr-3'
                                size='sm'
                                type='submit'
                                disabled={notification_sending}
                              >
                                Send Notification
                              </Button>
                            </AvForm>
                          </Col>
                        </Row>
                      </Col>
                      <Col md='6'>
                        <h5 className='text-capitalize mb-3'>
                          KD Rider App
                          <Badge pill className='badge-soft-success font-size-14 ml-2'>
                            Active
                          </Badge>
                        </h5>
                        <AvForm className='needs-validation' onValidSubmit={handleRiderAppUpdate}>
                          <Row>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Version</Label>
                                <AvField
                                  name='rider_app_version'
                                  placeholder='Enter Version'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Version is required.',
                                    },
                                  }}
                                  value={appInfo.rider_app.version}
                                  disabled={rider_app_updating}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Build Number</Label>
                                <AvField
                                  name='rider_app_build_number'
                                  placeholder='Enter Build Number'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Build Number is required.',
                                    },
                                  }}
                                  value={appInfo.rider_app.build_number}
                                  disabled={rider_app_updating}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Last Updated</Label>
                                <Input
                                  type='text'
                                  className='form-control'
                                  readOnly
                                  value={moment(new Date(appInfo.rider_app.last_updated)).format(
                                    'DD-MM-YYYY'
                                  )}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label style={{ opacity: 0 }}>update</Label>
                                <br />
                                <Button
                                  color='info'
                                  className='waves-effect waves-light mr-3'
                                  type='submit'
                                  disabled={rider_app_updating}
                                >
                                  {rider_app_updating ? 'Updating Info ...' : 'Update App'}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                        </AvForm>
                        <hr className='my-3' />
                        <Row>
                          <Col md='12'>
                            <AvForm
                              className='needs-validation'
                              onValidSubmit={handleSendNotification('rider_app')}
                              ref={rider_notification_form}
                            >
                              <FormGroup>
                                <Label>KD Rider App Update Details</Label>
                                <AvField
                                  name='notification_body'
                                  placeholder='Enter Notification Details ...'
                                  type='textarea'
                                  className='form-control'
                                  disabled={notification_sending}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Description is required',
                                    },
                                  }}
                                />
                              </FormGroup>
                              <Button
                                color='success'
                                className='waves-effect waves-light mr-3'
                                size='sm'
                                type='submit'
                                disabled={notification_sending}
                              >
                                Send Notification
                              </Button>
                            </AvForm>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
            <Col xs={6}>
              <Card>
                {appInfoLoading ? (
                  <div className='text-center my-3'>
                    <Loader type='TailSpin' color='#d3932c' height={80} width={80} />
                  </div>
                ) : !appInfoLoading && !appInfo ? (
                  <CardBody>
                    <h4 className='text-center'>No Data Found</h4>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row>
                      <Col md='6'>
                        <h5 className='text-capitalize mb-3'>
                          KD Whatsapp API
                        </h5>
                        <AvForm className='needs-validation' onValidSubmit={handleApiUpdate}>
                          <Row>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Access Token</Label>
                                <AvField
                                  name='access_token'
                                  placeholder='Enter access token'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Access token is required.',
                                    },
                                  }}
                                  value={whatsappDetail.accessToken}
                                  disabled={shop_app_updating}
                                />
                              </FormGroup>
                            </Col>
                            <Col md='6'>
                              <FormGroup>
                                <Label>Instance ID</Label>
                                <AvField
                                  name='instance_id'
                                  placeholder='Enter Instance Id'
                                  type='text'
                                  className='form-control'
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: 'Instance Id is required.',
                                    },
                                  }}
                                  value={whatsappDetail.instanceId}
                                  disabled={apiUpdating}
                                />
                              </FormGroup>
                            </Col>

                          </Row>
                          <Row>
                            <Col md="12">
                              <Button
                                color='success'
                                className='waves-effect waves-light mr-3'
                                size='sm'
                                type='submit'
                                disabled={apiUpdating}
                              >
                                Set Data
                              </Button>
                            </Col>
                          </Row>

                        </AvForm>
                      </Col>

                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MobileApp;
